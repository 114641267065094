<template>
    <ValidationObserver ref="subCategoryForm">
        <EKDialog
            :title="isEdit ? 'تعديل التصنيف الفرعي' : 'إضافة التصنيف الفرعي'"
            :placeholder="placeholder"
            btnText="تصنيف  فرعي جديد"
            ref="subCategoryDialog"
            @ok="createSubCategory"
            @delete="deleteSubCategory({id: categoryDto.id, parentCategoryId: categoryDto.parentCategoryId})"
            :isEdit="isEdit ? true : false"
            @close="reset"
        >
            <template #body>
                <EKInputText
                    v-model="categoryDto.name"
                    :rules="[
                        {
                            type: 'required',
                            message: 'اسم التصنيف الفرعي مطلوب'
                        }
                    ]"
                    label="اسم التصنيف الفرعي"
                    placeholder="ادخل اسم التصنيف الفرعي"
                    name="name"
                />

                <EKInputSelect
                    label="التصنيف الرئيسي"
                    placeholder="اختر تصنيف"
                    :rules="[
                        {
                            type: 'required',
                            message: 'التصنيف مطلوب'
                        }
                    ]"
                    :options="mainCategoriesList"
                    name="select"
                    :clearable="true"
                    v-model="categoryDto.parentCategoryId"
                />

                <EKInputTextarea
                    v-model="categoryDto.description"
                    label="وصف التصنيف الفرعي"
                    placeholder="ادخل وصف التصنيف الفرعي"
                    name="description"
                />

               <EKInputImage
                    ref="categoryImage"
                    label="صورة التصنيف الفرعي"
                    @input="categoryDto.imageFile = $event"
                    :value="
                        categoryDto.imagePath
                            ? $store.getters['app/domainHost'] +
                              '/' +
                              categoryDto.imagePath
                            : categoryDto.imageFile
                    "
                    title="أرفق الملف هنا أو اضغط لتحميل"
                ></EKInputImage>
            </template>
        </EKDialog>
    </ValidationObserver>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import EKDialog from "@Ekcore/components/EK-dialog";
import EKInputText from "@Ekcore/components/EK-forms/EK-input-text";
import EKInputTextarea from "@Ekcore/components/EK-forms/EK-input-textarea";
import EKInputImage from "@Ekcore/components/EK-forms/EK-input-image";
import { mapActions, mapState } from "vuex";
import { nullGuid } from "@Ekcore/util/global.js";
import EKInputSelect from "@Ekcore/components/EK-forms/EK-input-select";

export default {
    components: {
        ValidationObserver,
        EKDialog,
        EKInputText,
        EKInputImage,
        EKInputSelect,
        EKInputTextarea
    },
    data: () => ({
        nullGuid
    }),
    props: {
        isEdit: Boolean,
        id: String,
        placeholder: {
            type: String,
            default: () => "ابحث عن تصنيف محدد"
        },
    },
    computed: {
        ...mapState({
            subCategoriesList: state => state.categories.subCategoriesList,
            mainCategoriesList: state => state.categories.mainCategoriesList,
            categoryDto: state => state.categories.categoryDto
        })
    },
    methods: {
        ...mapActions([
            "addSubCategory",
            "updateSubCategory",
            "deleteSubCategory"
        ]),
        open() {
            this.$refs.subCategoryDialog.open();
        },  
        reset() {
            this.$refs.subCategoryForm.reset();
            this.$store.commit("Reset_Category_Dto");
            this.$refs.categoryImage.clear();
        },
        createSubCategory() {
            this.$refs.subCategoryForm.validate().then(success => {
                if (success) {
                    let categoryFormData = new FormData();
                    categoryFormData.append("id", this.categoryDto.id);
                    categoryFormData.append("parentCategoryId", this.categoryDto.parentCategoryId);
                    categoryFormData.append("name", this.categoryDto.name);
                    categoryFormData.append("description", this.categoryDto.description);
                    categoryFormData.append("imageFile", this.categoryDto.imageFile);
                    categoryFormData.append("imagePath", this.categoryDto.imagePath);
                    if (this.categoryDto.id == nullGuid) {
                        this.addSubCategory(categoryFormData);
                    } else {
                        categoryFormData.append("language", this.categoryDto.language);
                        this.updateSubCategory(categoryFormData);
                    }
                    this.$refs.subCategoryForm.reset();
                    this.$refs.categoryImage.clear();
                    this.$refs.subCategoryDialog.close();
                }
            });
        }
    },
    watch: {
        is(is) {
            if (!is) {
                this.$store.commit("Reset_Category_Dto");
            }
        }
    }
};
</script>
