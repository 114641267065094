<template>
  <div>
    <EKTable
      :items="mainCategoriesList"
      :columns="columns"
      @details="openCategoriesDetails"
      @delete-selected="fireDeleteCategoryEvent"
    >
      <template slot="items.dateCreated" scope="{value}">
        {{ new Date(value).toLocaleDateString("en-GB") }}
      </template>
      <template slot="items.details" slot-scope="{ props }">
        <div class="d-flex justify-content-center">
          <b-button
            v-b-tooltip.hover
            title="تعديل التصنيف الرئيسي"
            size="sm"
            variant="flat-secondary"
            class="btn-icon rounded-circle"
            @click="openEditMainCategoryDialog(props)"
          >
            <unicon name="pen" width="18" height="18"
          /></b-button>
        </div>
      </template>
    </EKTable>

    <b-card no-body class="mt-3" v-if="mainCategoriesList.length">
      <b-card-header
        class="p-1 d-flex justify-content-center justify-content-sm-between align-items-center"
      >
        <EKInputSelect
          @input="updateSubCategoriesList"
          style="min-width: 200px"
          class="d-block mt-1"
          placeholder="اختر تصنيف رئيسي"
          :options="mainCategoriesList"
          name="select"
          :clearable="true"
          :value="parentCategoryId"
        />

        <subcategoriesButtonAction
          ref="subCategoryDialog"
          placeholder=""
          :isEdit="isEdit"
          :id="id ? id : nullGuid"
        >
          <template slot="activator">
            <b-button
              variant="primary"
              @click="
                isEdit = false;
                openAddSubCategoryDialog();
              "
              >إضافة تصنيف فرعي جديد</b-button
            >
          </template>
        </subcategoriesButtonAction>
      </b-card-header>
    </b-card>

    <categoriesButtonAction
      ref="editMainCategoryDialog"
      title="تعديل التصنيف الرئيسي"
      placeholder
      btnText
    ></categoriesButtonAction>

    <transition name="fade">
      <div v-if="show && parentCategoryId">
        <EKTable
          class="mb-3"
          :items="subCategoriesList"
          :columns="subColumns"
          @details="openEditSubCategoryDialog"
          @delete-selected="fireDeleteSubCategoryEvent"
        >
          <template slot="items.dateCreated" slot-scope="{ value }">
            {{ new Date(value).toLocaleDateString("en-UK") }}
          </template>
        </EKTable>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import EKTable from "@Ekcore/components/EK-table";
import categoriesButtonAction from "./components/categories-button-action";
import subcategoriesButtonAction from "./components/subcategories-button-action";
import { nullGuid } from "@Ekcore/util/global.js";
import EKInputSelect from "@Ekcore/components/EK-forms/EK-input-select";
import { handleDashLangChange } from "@/libs/event-bus";

export default {
  components: {
    EKTable,
    categoriesButtonAction,
    subcategoriesButtonAction,
    EKInputSelect,
  },
  props: {
    id: String,
    seletcParentCategory: null,
  },
  computed: {
    ...mapState({
      categoryDto: (state) => state.categories.categoryDto,
      parentCategoryId: (state) => state.categories.parentCategoryId,
    }),
    ...mapGetters(["mainCategoriesList", "subCategoriesList"]),
  },
  data: () => ({
    nullGuid,
    show: false,
    isEdit: false,
    columns: [
      {
        label: "التصنيفات الرئيسية",
        field: "name",
      },
      {
        label: "تاريخ الإضافة",
        field: "dateCreated",
        sortable: false,
      },
      {
        label: "التصنيفات الفرعية",
        field: "categoriesChildrenCount",
        type: "number",
      },
      {
        label: "تفاصيل",
        field: "details",
      },
    ],
    subColumns: [
      {
        label: "اسم التصنيف الفرعي",
        field: "name",
      },
      {
        label: "تاريخ الإضافة",
        field: "dateCreated",
        sortable: false,
      },
      {
        label: "تفاصيل",
        field: "details",

        sortable: false,
      },
    ],
  }),
  created() {
    this.getMainCategories();
    this.Set_Parent_Category_Id(this.seletcParentCategory);
    if (+this.id) {
      this.show = true;
      this.getSubCategories(+this.id);
    }
    handleDashLangChange(() => {
      this.getMainCategories();
      this.Set_Parent_Category_Id(this.seletcParentCategory);
      if (+this.id) {
        this.show = true;
        this.getSubCategories(+this.id);
      }
    });
  },
  methods: {
    ...mapActions([
      "getMainCategories",
      "getSubCategories",
      "deleteRangeSubCategory",
      "deleteRangeCategoryList",
    ]),
    ...mapMutations([
      "Rest_Parent_Category_Id",
      "Set_Parent_Category_Id",
      "Reset_Sub_Category_List",
    ]),
    openCategoriesDetails(props) {
      if (props.row.id != this.id) {
        this.$router.push("/admin/categories/" + props.row.id);
      }
      this.getSubCategories(props.row.id);
      this.show = true;
    },
    openEditMainCategoryDialog(props) {
      this.$store.commit(
        "Reset_Category_Dto",
        this.mainCategoriesList.find((item) => item.id == props.row.id)
      );
      this.$refs.editMainCategoryDialog.open();
    },
    openAddSubCategoryDialog() {
      this.$store.commit("Reset_Category_Dto");
      this.$refs.subCategoryDialog.open();
    },
    openEditSubCategoryDialog(props) {
      this.$store.commit("Reset_Category_Dto", props.row);
      this.isEdit = true;
      this.$refs.subCategoryDialog.open();
    },
    fireDeleteCategoryEvent(list) {
      this.deleteRangeCategoryList(list);
    },
    fireDeleteSubCategoryEvent(list) {
      this.deleteRangeSubCategory({ ids: list, parentId: this.parentCategoryId });
    },
    updateSubCategoriesList(categoryId) {
      this.Set_Parent_Category_Id(categoryId);
      if (categoryId == null) {
        this.Reset_Sub_Category_List();
        return;
      }
      this.getSubCategories(categoryId);
      this.show = true;
    },
  },
  beforeDestroy() {
    this.$store.commit("Reset_Search_Dto");
  },
};
</script>
